<template>
  <section class="sub-container" ref="sub-container">
    <sub-nav-with-user-info>
      <sub-nav v-if="routeName === 'mypageCalcHistory'"></sub-nav
    ></sub-nav-with-user-info>
    <router-view></router-view>
  </section>
</template>

<script>
import SubNavWithUserInfo from 'Common/SubNavWithUserInfo'
import SubNav from 'Components/request/partials/SubNav'

export default {
  components: {
    SubNavWithUserInfo,
    SubNav,
  },
}
</script>

<style></style>
